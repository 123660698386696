import { PlusCircleIcon } from '@heroicons/react/solid';
import { Tooltip } from '@mui/material';
import React, { Component } from 'react';

import SlaProgressBar from '../utils/slaProgressBar';

const maxTitleLength = 120;

export default class H1Report extends Component {
    componentDidMount() {
        if (window.location.hash !== undefined && window.location.hash !== '') {
            let id = this.props.issue._id;
            if (window.location.hash === `#${id}`) {
                this.refs[id].focus();
            }
        }
    }
    render() {
        let { issue, index } = this.props;
        let report = issue;

        var severityBgColor = 'bg-gray-600';
        var severity = report.normalizedSeverity;
        if (severity === 'critical') {
            severityBgColor = 'bg-red-700';
        } else if (severity === 'high') {
            severityBgColor = 'bg-amber-600';
        } else if (severity === 'medium') {
            severityBgColor = 'bg-yellow-600';
        }
        var assignee = report.assignedName ? report.assignedName : report.assigned;
        var title = report.title;
        var link = 'https://hackerone.com/bugs?subject=99designs&report_id=' + report.id;
        if (title.length > maxTitleLength) title = title.substring(0, maxTitleLength - 3) + '...';
        var trelloDescription = encodeURIComponent(
            `**Details**\n\n * Title: ${report.title}\n * Reported Severity: ${severity}\n * Date Created: ${report.reported_at}\n * Report Link: ${link}\n * Generic Guidance: https://vistaprint.atlassian.net/wiki/spaces/SEC/pages/3251114575/Engaging+with+HackerOne+reports`,
        );
        var trelloLink =
            'https://trello.com/en-GB/add-card?' +
            'name=' +
            encodeURIComponent(`H1 Report: ${title}`) +
            '&' +
            'desc=' +
            trelloDescription;
        return (
            <tr
                className={
                    'text-sky-700 odd:bg-gray-200 even:bg-slate-100 focus:outline outline-2 decoration-2 underline-offset-2 hover:decoration-pink-400 hover:cursor-pointer hover:underline visited:text-sky-200'
                }
                tabIndex={index}
                ref={report._id}
                onClick={() => window.open(link, '_blank', 'noopener, noreferrer')}
            >
                <td
                    className="hover:text-green-500 hover:cursor-pointer visited:text-black"
                    onClick={(event) => {
                        event.stopPropagation();
                        window.open(trelloLink, '_blank', 'noopener, noreferrer');
                    }}
                >
                    <Tooltip title="Create Trello Card">
                        <PlusCircleIcon className="h-6" />
                    </Tooltip>
                </td>
                <td>{report.substate}</td>
                <td className="text-center">
                    <div
                        className={
                            'inline-block mx-1 px-2 uppercase text-gray-50 content-between text-center rounded-lg text-sm font-bold ' +
                            severityBgColor
                        }
                    >
                        {severity}
                    </div>
                </td>
                <td className="w-20">
                    <SlaProgressBar issue={report} />
                </td>
                <td>
                    {issue.daysElapsed} {issue.daysElapsed === 1 ? 'day' : 'days'}
                </td>
                <td>{assignee}</td>
                {report.title === title ? (
                    <td>{title}</td>
                ) : (
                    <td>
                        <Tooltip title={report.title}>
                            <div>{title}</div>
                        </Tooltip>
                    </td>
                )}
            </tr>
        );
    }
}
