import React from 'react';

export default function IssueCount({ issues }) {
    let criticalCount = issues.reduce(
        (count, issue) => (issue.normalizedSeverity === 'critical' ? ++count : count),
        0,
    );
    let highCount = issues.reduce(
        (count, issue) => (issue.normalizedSeverity === 'high' ? ++count : count),
        0,
    );
    let mediumCount = issues.reduce(
        (count, issue) => (issue.normalizedSeverity === 'medium' ? ++count : count),
        0,
    );
    let total = criticalCount + highCount + mediumCount;

    // do not count hackerone issues for SLA
    let nonH1Issues = issues.filter((issue) => issue.source !== 'HackerOne');
    let totalSla = nonH1Issues.reduce(
        (count, issue) => (issue.percentageSLA == 100 ? ++count : count),
        0,
    );

    return (
        <>
            <div className="flex justify-center content-center">
                <div className="flex">
                    <div className="flex-row bg-slate-200 rounded-2xl shadow-sm p-1 px-2 font-extrabold m-4">
                        <div className="text-center text-xl font-bold text-gray-700">Issues</div>
                        <div className="flex text-2xl font-mono text-center">
                            <div className="mx-2 text-white bg-slate-700 rounded-md">
                                <div className="m-1">{total}</div>
                                <div className="text-base m-1">Total</div>
                            </div>
                            <div className="mx-2 text-red-600">
                                <div className="m-1">{criticalCount}</div>
                                <div className="text-base">Critical</div>
                            </div>
                            <div className="mx-2 text-amber-700">
                                <div className="m-1">{highCount}</div>
                                <div className="text-base">High</div>
                            </div>
                            <div className="mx-2 text-yellow-600">
                                <div className="m-1">{mediumCount}</div>
                                <div className="text-base">Medium</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-row bg-slate-200 rounded-xl shadow-sm p-1 px-2 font-extrabold m-4">
                        <div className="text-center text-xl font-bold text-gray-700">SLA</div>
                        <div className="flex text-2xl font-mono text-center">
                            <div className="mx-2 text-white bg-slate-700 rounded-md">
                                <div className="m-1">{totalSla}</div>
                                <div className="text-base m-1">Violations</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
