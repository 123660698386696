import React from 'react';
import NavBar from './components/navBar';

function App() {
    return (
        <div className="App font-sans bg-gray-50 overflow-x-hidden">
            <NavBar />
        </div>
    );
}

export default App;
