import { Tooltip } from '@mui/material';
import React, { Component } from 'react';

export default class SlaProgressBar extends Component {
    render() {
        let daysElapsed = this.props.issue.daysElapsed || 0;
        let target = Infinity;
        let severity = this.props.issue.normalizedSeverity;
        if (severity === 'critical') {
            target = 0;
        } else if (severity === 'high') {
            target = 6;
        } else if (severity === 'medium') {
            target = 30;
        }
        let percentageSLA = this.props.issue.percentageSLA;
        let percentageRevSLA = (100 - percentageSLA).toString() + '%';
        let tooltip = (
            <div>
                Days Elapsed = {daysElapsed} <br /> SLA Target = {target}
            </div>
        );
        return (
            <Tooltip title={tooltip}>
                <div className="w-full bg-gradient-to-r from-green-600 to-red-500 h-3 relative rounded-full">
                    <div
                        className="relative bg-gray-300 h-3 ml-auto mr-0"
                        style={{ width: percentageRevSLA }}
                    ></div>
                </div>
            </Tooltip>
        );
    }
}
