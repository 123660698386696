import { PlusCircleIcon } from '@heroicons/react/solid';
import { Tooltip } from '@mui/material';
import React, { Component } from 'react';

import SlaProgressBar from '../utils/slaProgressBar';

export default class RedxIssue extends Component {
    componentDidMount() {
        if (window.location.hash !== undefined && window.location.hash !== '') {
            let id = this.props.issue._id;
            if (window.location.hash === `#${id}`) {
                this.refs[id].focus();
            }
        }
    }
    render() {
        let { issue, index } = this.props;

        var severityBgColor = 'bg-gray-600';
        var severity = issue.normalizedSeverity;
        if (severity === 'critical') {
            severityBgColor = 'bg-red-700';
        } else if (severity === 'high') {
            severityBgColor = 'bg-amber-600';
        } else if (severity === 'medium') {
            severityBgColor = 'bg-yellow-600';
        }
        var accountName = issue.accountName.startsWith('aws-99designs-')
            ? issue.accountName.slice('aws-99designs-'.length)
            : issue.accountName;
        var title = `${issue.checkName} for ${issue.resource}`;
        var trelloDescriptionText = `**Details**\n
* Check: ${issue.checkName}
* Severity: ${issue.normalizedSeverity}
* Account: ${issue.accountName} (${issue.accountId})
* Resource: ${issue.resource}
* Zone Name: ${issue.zoneName}
* Zone Id: ${issue.zoneId}
* Reference: https://cimpress-support.atlassian.net/l/cp/QMNnYN2D`;
        var trelloDescription = encodeURIComponent(trelloDescriptionText);
        var trelloLink =
            'https://trello.com/en-GB/add-card?' +
            'name=' +
            encodeURIComponent(`Red-X Report: ${title}`) +
            '&' +
            'desc=' +
            trelloDescription;
        return (
            <tr
                className={'text-sky-700 odd:bg-gray-200 even:bg-slate-100 focus:outline outline-2'}
                tabIndex={index}
                ref={issue._id}
            >
                <td
                    className="hover:text-green-500 hover:cursor-pointer visited:text-black"
                    onClick={(event) => {
                        event.stopPropagation();
                        window.open(trelloLink, '_blank', 'noopener,noreferrer');
                    }}
                >
                    <Tooltip title="Create Trello Card">
                        <PlusCircleIcon className="h-6" />
                    </Tooltip>
                </td>
                <td className="capitalize">{issue.group}</td>
                <td className="capitalize">{issue.squad}</td>
                <td className="capitalize">{accountName}</td>
                <td className="text-center">
                    <div
                        className={
                            'inline-block mx-1 px-2 uppercase text-gray-50 content-between text-center rounded-lg text-sm font-bold ' +
                            severityBgColor
                        }
                    >
                        {issue.normalizedSeverity}
                    </div>
                </td>
                <td className="w-20">
                    <SlaProgressBar issue={issue} />
                </td>
                <td>
                    {issue.daysElapsed} {issue.daysElapsed === 1 ? 'day' : 'days'}
                </td>
                <td>{issue.checkName}</td>
                <td>{issue.resource}</td>
                <td className="font-mono text-sm">{issue.zoneId}</td>
            </tr>
        );
    }
}
