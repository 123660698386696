import React, { Component } from 'react';
import { CartesianGrid, Label, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';

export default class OverallHistoryChart extends Component {
    render() {
        let history = this.props.history;
        let sources = this.props.sources;
        let sourcesLowerCase = sources.map((s) => s.toLowerCase());
        let sourceHistory = history.filter((h) =>
            sourcesLowerCase.includes(h.source.toLowerCase()),
        );
        let allDates = sourceHistory.reduce((result, h) => {
            result.add(h.date);
            return result;
        }, new Set());

        // sort ascending
        let allSortedDates = Array.from(allDates).sort().reverse();

        // calculate total issues on each date for each severity
        let totalHistory = [];
        for (let date of allSortedDates) {
            for (let severity of ['critical', 'high', 'medium', 'low']) {
                let total = 0;
                for (let source of sourcesLowerCase) {
                    let h = sourceHistory.find(
                        (h) =>
                            h.date === date &&
                            h.source.toLowerCase() === source &&
                            h.severity === severity,
                    );
                    if (h !== undefined) {
                        total += h.count;
                    }
                }
                let data = { date: date };
                data[severity] = total;
                totalHistory.push(data);
            }
        }

        let forGraph = [];
        for (let date of allSortedDates) {
            let data = { date: date };
            for (let severity of ['critical', 'high', 'medium', 'low']) {
                data[severity] = totalHistory.find((h) => severity in h && h.date === date)[
                    severity
                ];
            }
            forGraph.push(data);
        }
        forGraph = forGraph.reverse();

        let visuals = [
            { label: 'critical', color: '#DC2626', strokeWidth: 2 },
            { label: 'high', color: '#B45309', strokeWidth: 2 },
            { label: 'medium', color: '#CA8A04', strokeWidth: 1 },
            { label: 'low', color: '#4B5563', strokeWidth: 1 },
        ];

        return (
            <div className="flex justify-center text-center m-1">
                <div className="bg-gray-100 rounded-lg">
                    <div className="text-gray-800 font-semibold">
                        Overall History ({sources.toString()})
                    </div>
                    <LineChart width={600} height={250} data={forGraph}>
                        <XAxis dataKey="date" />
                        <YAxis>
                            <Label value="Issues" angle="-90" position="insideLeft" />
                        </YAxis>
                        <Tooltip />
                        <Legend />
                        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                        {visuals.map((visual) => (
                            <Line
                                type="monotone"
                                dot={false}
                                strokeWidth={visual.strokeWidth}
                                key={visual.label}
                                dataKey={visual.label}
                                stroke={visual.color}
                                animationDuration={500}
                            />
                        ))}
                    </LineChart>
                </div>
            </div>
        );
    }
}
