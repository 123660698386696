import React from 'react';
import { Component } from 'react';
import H1Report from './h1Report';
import InfoCard from '../utils/infoCard';
import LoadingSpinner from '../utils/loading';
import LoadingError from '../utils/loadingError';
import NoIssues from '../utils/noIssues';
import Table from '../utils/table';

export default class HackerOne extends Component {
    render() {
        var reports = this.props.data;
        reports.sort((a, b) => b.normalizedScore - a.normalizedScore);

        if (this.props.isLoadingError) {
            return <LoadingError />;
        } else if (this.props.isLoading) {
            return <LoadingSpinner />;
        } else {
            if (reports.length === 0) {
                return <NoIssues />;
            } else {
                return (
                    <div className="mb-4 flex flex-col">
                        <div className="text-center mt-4">
                            <InfoCard heading="How is severity calculated?">
                                <div>
                                    If the severity score exists then it is used. Else if the
                                    severity rating exists it is used to calculate score. If the
                                    issue does not have severity score or severity rating
                                    (untriaged) the severity is set to high.
                                </div>
                            </InfoCard>
                            <InfoCard heading="Domain filter is not working">
                                <div>
                                    As we are migrating away from BambooHR we currently do not have
                                    a great way of getting user details and hence finding the domain
                                    membership. For untriaged/new reports please volunteer to triage
                                    them. The process can be found on{' '}
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://vistaprint.atlassian.net/wiki/spaces/SEC/pages/3251114575/Engaging+with+HackerOne+reports"
                                    >
                                        this page
                                    </a>
                                </div>
                            </InfoCard>
                        </div>
                        <Table
                            data={reports}
                            columns={[
                                { label: 'Trello', accessor: '_id', sortable: false },
                                { label: 'State', accessor: 'substate', sortable: true },
                                { label: 'Severity', accessor: 'normalizedScore', sortable: true },
                                { label: 'SLA', accessor: 'percentageSLA', sortable: true },
                                { label: 'Days Elapsed', accessor: 'daysElapsed', sortable: true },
                                { label: 'Assignee', accessor: 'assignedName', sortable: true },
                                { label: 'Title', accessor: 'title', sortable: true },
                            ]}
                            RowClass={H1Report}
                        />
                    </div>
                );
            }
        }
    }
}
