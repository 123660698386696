import React, { Component } from 'react';
import SnykCodeIssue from './snykCodeIssue';
import InfoCard from '../utils/infoCard';
import LoadingSpinner from '../utils/loading';
import LoadingError from '../utils/loadingError';
import NoIssues from '../utils/noIssues';
import HistoryChart from '../charts/history';
import Table from '../utils/table';

export default class SnykCode extends Component {
    render() {
        var issues = this.props.issues;
        issues.sort((a, b) => parseFloat(b.normalizedScore) - parseFloat(a.normalizedScore));

        if (this.props.isLoadingError) {
            return <LoadingError />;
        } else if (this.props.isLoading) {
            return <LoadingSpinner />;
        } else {
            if (issues.length === 0) {
                return <NoIssues />;
            } else {
                return (
                    <div className="flex flex-col mb-10">
                        <div className="text-center mt-4">
                            <HistoryChart history={this.props.history} source="Snyk" />
                            <InfoCard heading="How is severity calculated?">
                                <div>
                                    Severity is calculated by considering{' '}
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://docs.snyk.io/features/fixing-and-prioritizing-issues/starting-to-fix-vulnerabilities/snyk-priority-score"
                                    >
                                        Snyk Priority Score
                                    </a>{' '}
                                    as well as other factors such as issue type and data
                                    classification of what the repo/app interacts with.
                                </div>
                            </InfoCard>
                            <InfoCard heading="Why do I see more issues in Snyk?">
                                <div>
                                    99vulns displays only actionable issues. To identify actionable
                                    issues we check if the issue has a fix version. Snyk however
                                    shows issues regardless of existence of fix versions. Moreover,
                                    99vulns shows critical and high severity issues (based on CVSS
                                    score) only.
                                </div>
                            </InfoCard>
                        </div>
                        <Table
                            data={issues}
                            columns={[
                                { label: 'Trello', accessor: '_id', sortable: false },
                                { label: 'Domain', accessor: 'group', sortable: true },
                                { label: 'Project', accessor: 'projectName', sortable: true },
                                { label: 'Severity', accessor: 'normalizedScore', sortable: true },
                                { label: 'SLA', accessor: 'percentageSLA', sortable: true },
                                { label: 'Days Elapsed', accessor: 'daysElapsed', sortable: true },
                                { label: 'Title', accessor: 'title', sortable: true },
                            ]}
                            RowClass={SnykCodeIssue}
                        />
                    </div>
                );
            }
        }
    }
}
