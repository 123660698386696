const TableBody = ({ tableData, RowClass }) => {
    return (
        <tbody>
            {tableData.map((issue, index) => (
                <RowClass issue={issue} index={index + 10} key={issue._id} />
            ))}
        </tbody>
    );
};

export default TableBody;
