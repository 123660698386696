import React, { Component } from 'react';
import InfoCard from '../utils/infoCard';
import LoadingSpinner from '../utils/loading';
import LoadingError from '../utils/loadingError';
import NoIssues from '../utils/noIssues';
import HistoryChart from '../charts/history';
import Table from '../utils/table';
import OrcaIssue from './orcaIssue';

export default class Orca extends Component {
    render() {
        var issues = this.props.issues;
        if (issues.length > 0) {
            issues.sort((a, b) => {
                let p, q;
                if (a.testSeverity === 'high') p = 10;
                if (a.testSeverity === 'medium') p = 5;
                if (a.testSeverity === 'low') p = 0;
                if (b.testSeverity === 'high') q = 10;
                if (b.testSeverity === 'medium') q = 5;
                if (b.testSeverity === 'low') q = 0;
                return q - p;
            });
        }
        if (this.props.isLoadingError) {
            return <LoadingError />;
        } else if (this.props.isLoading) {
            return <LoadingSpinner />;
        } else {
            if (issues.length === 0) {
                return <NoIssues />;
            } else {
                return (
                    <div className="pb-10 flex flex-col">
                        <HistoryChart history={this.props.history} source="Orca" />
                        <div className="text-center mt-4">
                            <InfoCard heading="What is Orca?">
                                <div>
                                    Orca Security is an agentless Cloud Security Platform that
                                    identifies, prioritizes, and remediates risks in Cloud Assets.
                                    It supports the three major cloud platforms: AWS, GCP, and
                                    Azure.
                                    <br />
                                    More details on Orca can be found at{' '}
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="https://support.security.cimpress.io/hc/en-us/articles/5766605028508-Cloud-Vulnerability-Management-Orca"
                                    >
                                        this Confluence Page
                                    </a>
                                    .
                                </div>
                            </InfoCard>
                        </div>
                        <Table
                            data={issues}
                            columns={[
                                { label: 'Trello', accessor: '_id', sortable: false },
                                { label: 'Org', accessor: 'group', sortable: true },
                                { label: 'Squad', accessor: 'squad', sortable: true },
                                { label: 'AWS Account', accessor: 'account_name', sortable: true },
                                { label: 'Type', accessor: 'type_string', sortable: true },
                                { label: 'Severity', accessor: 'normalizedScore', sortable: true },
                                { label: 'SLA', accessor: 'percentageSLA', sortable: true },
                                { label: 'Days Elapsed', accessor: 'daysElapsed', sortable: true },
                                { label: 'Details', accessor: 'details', sortable: true },
                                { label: 'Source', accessor: 'source', sortable: true },
                                { label: 'Asset', accessor: 'asset_unique_id', sortable: true },
                            ]}
                            RowClass={OrcaIssue}
                        />
                    </div>
                );
            }
        }
    }
}
