import React from 'react';
import { useNavigate } from 'react-router-dom';
import SlaProgressBar from '../utils/slaProgressBar';
import { SiSnyk } from '@react-icons/all-files/si/SiSnyk';
import { SiHackerone } from '@react-icons/all-files/si/SiHackerone';
import OrcaLogo from '../images/orcaLogo.svg';
import CimpressLogo from '../images/cimpressCloudLogo.svg';
import { Tooltip } from '@mui/material';

const maxMessageLength = 110;

export default function GenericIssue({ issue, index }) {
    const navigate = useNavigate();
    let score = parseFloat(issue.normalizedScore);
    var scoreBgColor = 'bg-gray-600';
    if (score >= 9.0) {
        scoreBgColor = 'bg-red-600';
    } else if (score >= 7) {
        scoreBgColor = 'bg-amber-700';
    } else if (score >= 4) {
        scoreBgColor = 'bg-yellow-600';
    }
    let link = `/${issue.source.toLowerCase()}#${issue._id}`;
    let source = issue.source;
    if (issue.source.toLowerCase() === 'snyk') {
        source = <SiSnyk className="w-6 h-5 inline-block" />;
    } else if (issue.source.toLowerCase() === 'code') {
        source = <SiSnyk className="w-6 h-5 inline-block" />;
    } else if (issue.source.toLowerCase() === 'hackerone') {
        source = <SiHackerone className="py-[1px] px-1 w-6 h-5 inline-block" />;
    } else if (issue.source.toLowerCase() === 'redx') {
        source = (
            <div className="py-[1px] pl-1 pb-1 inline-block align-middle">
                <img src={CimpressLogo} className="w-5 h-5" />
            </div>
        );
    } else if (issue.source.toLowerCase() === 'orca') {
        source = (
            <div className="py-[1px] pl-1 pb-1 inline-block align-middle">
                <img src={OrcaLogo} className="w-5 h-5" />
            </div>
        );
    } else {
        source = <></>;
    }
    let showName = issue.name;
    if (showName.length > maxMessageLength)
        showName = showName.substring(0, maxMessageLength - 3) + '...';
    return (
        <tr
            className="hover:underline hover:cursor-pointer decoration-2 underline-offset-2 hover:decoration-pink-400 text-sky-700 odd:bg-gray-200 even:bg-slate-100"
            onClick={() => navigate(link)}
            tabIndex={index}
        >
            <td className="text-center">
                <div
                    className={
                        'inline-block mx-1 px-2 uppercase text-gray-50 content-between rounded-lg text-sm font-bold ' +
                        scoreBgColor
                    }
                >
                    {issue.normalizedSeverity}
                </div>
            </td>
            <td className="content-between align-middle">
                <div>
                    {source}
                    {issue.source}
                </div>
            </td>
            <td className="w-20">
                <SlaProgressBar issue={issue} />
            </td>
            <td>
                {issue.daysElapsed} {issue.daysElapsed === 1 ? 'day' : 'days'}
            </td>
            {showName === issue.name ? (
                <td className="mx-1">{showName}</td>
            ) : (
                <Tooltip title={issue.name}>
                    <td className="mx-1">{showName}</td>
                </Tooltip>
            )}
        </tr>
    );
}
