import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { NavLink as BaseNavLink } from 'react-router-dom';
import HackerOne from './h1';
import Login from './login';
import Snyk from './snyk';
import SnykCode from './snykCode';
import Dashboard from './dashboard';
import banner from '../images/99vulns.png';
import { centralizedAuth } from '@cimpress/simple-auth-wrapper';
import { UserContext } from '../user-context';
import LoadingSpinner from '../utils/loading';
import Redx from './redx';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { Tooltip } from '@mui/material';
import Orca from './orca';

// hack for react router v6 - https://reactrouterdotcom.fly.dev/docs/en/v6/components/nav-link
const NavLink = React.forwardRef(({ activeClassName, activeStyle, ...props }, ref) => {
    return (
        <BaseNavLink
            ref={ref}
            {...props}
            className={({ isActive }) =>
                [props.className, isActive ? activeClassName : null].filter(Boolean).join(' ')
            }
            style={({ isActive }) => ({
                ...props.style,
                ...(isActive ? activeStyle : null),
            })}
        />
    );
});

export default class NavBar extends Component {
    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            userProfile: { given_name: 'loading', family_name: 'name' },
            snyk: [],
            snykCode: [],
            h1: [],
            orca: [],
            redx: [],
            history: [],
            slaViolation: [],
            slaViolationAll: [],
            isLoading: true,
            isLoadingError: false,
            group: localStorage.getItem('group') ? localStorage.getItem('group') : 'all',
        };
    }

    componentDidMount() {
        const auth = new centralizedAuth({
            clientID: 'tOT7y2KmoIXVsYtQLTKYOZFCihftf4gx',
            redirectUri: '/login',
        });
        this.context.auth = auth;
        auth.on('tokenExpired', () => {
            // This is invoked each time the current token in localStorage becomes expired.
            // This will also be immediately invoked if the token in localStorage is already expired. (configurable via emitInitialTokenExpired)
            auth.login();
        });
        auth.ensureAuthentication(window.location)
            .then((isAuthenticated) => {
                if (isAuthenticated) {
                    let userProfile = this.context.auth.getProfile();
                    this.context.userProfile = userProfile;
                    this.setState({ userProfile: userProfile });
                    this.getData(this.state.group);
                } else {
                    console.log('not logged in!!!');
                }
            })
            .catch((err) => {
                // handle authentication error
                console.error(`Error with ensureAuthentication: ${err}`);
            });
        this.setState({ auth: auth });

        // refresh data every 1 hour
        setInterval(
            () => {
                this.getData(localStorage.getItem('group') ? localStorage.getItem('group') : 'all');
            },
            1 * 60 * 60 * 1000,
        );
    }

    getData(group) {
        this.setState({ isLoading: true });
        const { REACT_APP_API_BASE } = process.env;

        let fetchOptions = {
            headers: {
                Authorization: `Bearer ${this.context.auth.getAccessToken()}`,
            },
        };

        var promises = [];
        var h1Promise = fetch(
            REACT_APP_API_BASE +
                '/api/h1?' +
                new URLSearchParams({
                    group: group,
                }),
            fetchOptions,
        );

        var snykPromise = fetch(
            REACT_APP_API_BASE +
                '/api/snyk?' +
                new URLSearchParams({
                    group: group,
                }),
            fetchOptions,
        );

        var snykCodePromise = fetch(
            REACT_APP_API_BASE +
                '/api/snykCode?' +
                new URLSearchParams({
                    group: group,
                }),
            fetchOptions,
        );

        var redxPromise = fetch(
            REACT_APP_API_BASE +
                '/api/redx?' +
                new URLSearchParams({
                    group: group,
                }),
            fetchOptions,
        );

        var orcaPromise = fetch(
            REACT_APP_API_BASE +
                '/api/orca?' +
                new URLSearchParams({
                    group: group,
                }),
            fetchOptions,
        );
        var historyPromise = fetch(
            REACT_APP_API_BASE +
                '/api/history?' +
                new URLSearchParams({
                    group: group,
                }),
            fetchOptions,
        );

        var slaViolationPromise = fetch(
            REACT_APP_API_BASE +
                '/api/slaviolation?' +
                new URLSearchParams({
                    group: group,
                }),
            fetchOptions,
        );

        var slaViolationAllGroupPromise = fetch(
            REACT_APP_API_BASE + '/api/slaviolation?group=all',
            fetchOptions,
        );

        promises.push(
            h1Promise,
            snykPromise,
            snykCodePromise,
            redxPromise,
            orcaPromise,
            historyPromise,
            slaViolationPromise,
            slaViolationAllGroupPromise,
        );
        Promise.all(promises)
            .then(async (promises) => {
                var [
                    h1Promise,
                    snykPromise,
                    snykCodePromise,
                    redxPromise,
                    orcaPromise,
                    historyPromise,
                    slaViolationPromise,
                    slaViolationAllGroupPromise,
                ] = promises;
                var h1 = await h1Promise.json();
                var snyk = await snykPromise.json();
                var snykCode = await snykCodePromise.json();
                var redx = await redxPromise.json();
                var orca = await orcaPromise.json();
                var history = await historyPromise.json();
                var slaViolation = await slaViolationPromise.json();
                var slaViolationAll = await slaViolationAllGroupPromise.json();
                this.setState({
                    h1: h1,
                    snyk: snyk,
                    snykCode: snykCode,
                    redx: redx,
                    orca: orca,
                    history: history,
                    slaViolation: slaViolation,
                    slaViolationAll: slaViolationAll,
                    isLoading: false,
                    isLoadingError: false,
                });

                // if old group is used, reset group
                if (snyk?.message === 'invalid group') {
                    localStorage.clear(group);
                    alert('Invalid domain, refresh the page to fix this issue.');
                }
            })
            .catch((err) => {
                console.error(`Error loading: ${err}`);
                this.setState({ isLoadingError: true, isLoading: false });
            });
    }

    render() {
        const groupChange = (event) => {
            let group = event.target.value;
            localStorage.setItem('group', group);
            this.getData(group);
        };

        if (this.context && 'auth' in this.context && this.context.auth.isLoggedIn()) {
            return (
                <div className="w-full overflow-auto h-screen flex flex-col">
                    <div className="flex bg-slate-600 text-lg font-semibold text-blue-50 top-0">
                        <div className="space-x-4 grow flex py-1">
                            <img
                                className="my-1 px-1 h-7 overflow-visible"
                                draggable={false}
                                src={banner}
                                alt="99vulns"
                            />
                            <NavLink
                                className="my-1 underline-offset-4 decoration-4 decoration-pink-400 hover:text-white"
                                activeClassName="underline"
                                to={'/'}
                                end
                            >
                                Home
                            </NavLink>
                            <NavLink
                                className="my-1 underline-offset-4 decoration-4 decoration-pink-400 hover:text-white"
                                activeClassName="underline"
                                to={'/snyk'}
                            >
                                Snyk
                            </NavLink>
                            <NavLink
                                className="my-1 underline-offset-4 decoration-4 decoration-pink-400 hover:text-white"
                                activeClassName="underline"
                                to={'/code'}
                            >
                                Code
                            </NavLink>
                            <NavLink
                                className="my-1 underline-offset-4 decoration-4 decoration-pink-400 hover:text-white"
                                activeClassName="underline"
                                to={'/hackerone'}
                            >
                                HackerOne
                            </NavLink>
                            <NavLink
                                className="my-1 underline-offset-4 decoration-4 decoration-pink-400 hover:text-white"
                                activeClassName="underline"
                                to={'/orca'}
                            >
                                Orca
                            </NavLink>
                            <NavLink
                                className="my-1 underline-offset-4 decoration-4 decoration-pink-400 hover:text-white"
                                activeClassName="underline"
                                to={'/redx'}
                            >
                                RedX
                            </NavLink>

                            <div className="my-1 space-x-2 grow text-center font-normal mx-2">
                                <select
                                    onChange={groupChange}
                                    defaultValue={this.state.group}
                                    className="text-center rounded-lg flex-2 border-dotted bg-blue-50 text-slate-600"
                                >
                                    <option value="all">Domain: All</option>
                                    <option value="expert-services">Expert Services</option>
                                    <option value="logo-&-brand">Logo & Brand</option>
                                </select>
                            </div>
                        </div>

                        <div className="flex">
                            <Tooltip title="Issues or Feature Requests">
                                <a
                                    className="hover:text-white my-1 flex"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://gitlab.com/vistaprint-org/99designs/99vulns/-/issues"
                                >
                                    <QuestionMarkCircleIcon className="w-6 my-1" />
                                </a>
                            </Tooltip>

                            <NavLink
                                to="/login"
                                className="py-1 my-1 decoration-4 underline-offset-4 decoration-pink-400 hover:text-white"
                                activeClassName="underline"
                            >
                                {this.state && this.state.userProfile && (
                                    <h2 className="capitalize mx-2">
                                        {this.state.userProfile.given_name}{' '}
                                        {this.state.userProfile.family_name}
                                    </h2>
                                )}
                            </NavLink>
                        </div>
                    </div>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <Dashboard
                                    data={this.state}
                                    isLoading={this.state.isLoading}
                                    isLoadingError={this.state.isLoadingError}
                                />
                            }
                        />
                        <Route
                            path="/hackerone"
                            element={
                                <HackerOne
                                    data={this.state.h1}
                                    history={this.state.history}
                                    isLoading={this.state.isLoading}
                                    isLoadingError={this.state.isLoadingError}
                                />
                            }
                        />
                        <Route
                            path="/orca"
                            element={
                                <Orca
                                    issues={this.state.orca}
                                    history={this.state.history}
                                    isLoading={this.state.isLoading}
                                    isLoadingError={this.state.isLoadingError}
                                />
                            }
                        />
                        <Route
                            path="/snyk"
                            element={
                                <Snyk
                                    issues={this.state.snyk}
                                    history={this.state.history}
                                    isLoading={this.state.isLoading}
                                    isLoadingError={this.state.isLoadingError}
                                />
                            }
                        />
                        <Route
                            path="/code"
                            element={
                                <SnykCode
                                    issues={this.state.snykCode}
                                    history={this.state.history}
                                    isLoading={this.state.isLoading}
                                    isLoadingError={this.state.isLoadingError}
                                />
                            }
                        />
                        <Route
                            path="/redx"
                            element={
                                <Redx
                                    issues={this.state.redx}
                                    history={this.state.history}
                                    isLoading={this.state.isLoading}
                                    isLoadingError={this.state.isLoadingError}
                                />
                            }
                        />
                        <Route path="/login" element={<Login />} />
                    </Routes>
                </div>
            );
        } else {
            return (
                <div className="h-screen flex">
                    <LoadingSpinner />
                </div>
            );
        }
    }
}
