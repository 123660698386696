import React, { Component } from 'react';
import { CartesianGrid, Label, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';

export default class SlaHistoryChart extends Component {
    render() {
        let slaViolation = this.props.slaViolation;

        let allDates = slaViolation.reduce((result, h) => {
            result.add(h.date);
            return result;
        }, new Set());

        let sourcesSet = slaViolation.reduce((result, h) => {
            result.add(h.source);
            return result;
        }, new Set());
        let allSources = Array.from(sourcesSet);

        // sort ascending
        let allSortedDates = Array.from(allDates).sort().reverse();

        for (let violation of slaViolation) {
            violation[violation.source] = violation.count;
        }

        let forGraph = [];
        for (let date of allSortedDates) {
            let data = { date: date };
            let totalOnDate = 0;
            for (let source of allSources) {
                var d = slaViolation.find((v) => v.source === source && v.date === date);
                if (d) {
                    data[source] = d['count'];
                    totalOnDate += d['count'];
                } else {
                    data[source] = 0;
                }
            }
            data.total = totalOnDate;
            forGraph.push(data);
        }
        forGraph = forGraph.reverse();
        let colors = ['#e18a1e', '#8884d8', '#82ca9d', '#ffc658', 'gray', 'purple', 'orange'];

        return (
            <div className="flex justify-center text-center m-1">
                <div className="bg-gray-100 rounded-lg">
                    <div className="text-gray-800 font-semibold">SLA Violations</div>
                    <LineChart width={600} height={250} data={forGraph}>
                        <XAxis dataKey="date" />
                        <YAxis>
                            <Label value="count" angle="-90" position="insideLeft" />
                        </YAxis>
                        <Tooltip />
                        <Legend />
                        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                        <Line
                            type="monotone"
                            dot={false}
                            strokeWidth={2}
                            key="total"
                            dataKey="total"
                            stroke={colors[0]}
                            animationDuration={500}
                        />
                        {allSources.map((source, index) => (
                            <Line
                                type="monotone"
                                dot={false}
                                strokeWidth={1}
                                key={source}
                                dataKey={source}
                                stroke={colors[index + 1]}
                                animationDuration={500}
                            />
                        ))}
                    </LineChart>
                </div>
            </div>
        );
    }
}
