import React, { Component } from 'react';
import { PlusCircleIcon } from '@heroicons/react/solid';
import SlaProgressBar from '../utils/slaProgressBar';
import { Tooltip } from '@mui/material';

const maxTitleLength = 60;
export default class SnykIssue extends Component {
    componentDidMount() {
        if (window.location.hash !== undefined) {
            let id = this.props.issue._id;
            if (window.location.hash === `#${id}`) {
                this.refs[id].focus();
            }
        }
    }
    render() {
        let { issue, index } = this.props;
        let score = parseFloat(issue.normalizedScore);
        var scoreBgColor = 'bg-gray-600';
        if (score >= 9.0) {
            scoreBgColor = 'bg-red-700';
        } else if (score >= 7) {
            scoreBgColor = 'bg-amber-600';
        } else if (score >= 4) {
            scoreBgColor = 'bg-yellow-600';
        }
        var title = issue.issueData.title;
        if (title.length > maxTitleLength) title = title.substring(0, maxTitleLength - 3) + '...';
        var link = `https://app.snyk.io/org/${issue.orgName}/project/${issue.projectId}#issue-${issue.id}`;
        var projectName = issue.projectName;
        if (projectName.startsWith('github.com/'))
            projectName = projectName.slice('github.com/'.length);
        if (projectName.startsWith('vistaprint-org/'))
            projectName = projectName.slice('vistaprint-org/'.length);
        if (projectName.startsWith('99designs/'))
            projectName = projectName.slice('99designs/'.length);
        var pkgName = issue.pkgName;
        if (pkgName.startsWith('golang.org/')) {
            pkgName = pkgName.slice('golang.org/'.length);
        } else if (pkgName.startsWith('github.com/')) {
            pkgName = pkgName.slice('github.com/'.length);
        } else if (pkgName.startsWith('gopkg.in/')) {
            pkgName = pkgName.slice('gopkg.in/'.length);
        }
        var domain = issue.group.replaceAll('-', ' ');

        let trelloDescription = encodeURIComponent(
            `**Issue Details**\n\n * Title: ${issue.issueData.title}\n * Package: ${issue.pkgName}\n * Project: \`${issue.projectName}\`\n * Severity: ${issue.normalizedSeverity}\n * Origin: ${issue.projectOrigin}\n * Type: ${issue.projectType}\n * Language: ${issue.issueData.language}\n * Snyk link: ${link}`,
        );
        let trelloLink =
            'https://trello.com/en-GB/add-card?' +
            'name=' +
            encodeURIComponent(
                `Snyk Issue: ${issue.issueData.title} in ${issue.pkgName} in ${issue.projectName}`,
            ) +
            '&' +
            'desc=' +
            trelloDescription;
        let tooltipTitle = (
            <div>
                Priority Score = {issue.priorityScore}
                <br />
                CVSS Score = {issue.issueData.cvssScore}
                <br />
                Severity = {issue.normalizedSeverity}
            </div>
        );
        return (
            <tr
                className={
                    'hover:underline hover:cursor-pointer decoration-2 underline-offset-2 hover:decoration-pink-400 text-sky-700 odd:bg-gray-200 even:bg-slate-100 focus:outline outline-2'
                }
                tabIndex={index}
                autoFocus
                onClick={() => window.open(link, '_blank', 'noopener,noreferrer')}
                ref={issue._id}
            >
                <td
                    className="hover:text-green-500 hover:cursor-pointer visited:text-black"
                    onClick={(event) => {
                        event.stopPropagation();
                        window.open(trelloLink, '_blank', 'noopener,noreferrer');
                    }}
                >
                    <Tooltip title="Create Trello Card">
                        <PlusCircleIcon className="h-6" />
                    </Tooltip>
                </td>
                <td className="capitalize">{domain}</td>
                <td>{projectName}</td>
                <td>{issue.issueData.language}</td>
                <td>
                    <Tooltip title={pkgName === issue.pkgName ? '' : issue.pkgName}>
                        <div>{pkgName}</div>
                    </Tooltip>
                </td>
                <td className="text-center">
                    <div
                        className={
                            'inline-block mx-1 px-2 uppercase text-gray-50 content-between text-center rounded-lg text-sm font-bold ' +
                            scoreBgColor
                        }
                    >
                        <Tooltip title={tooltipTitle}>
                            <div>{score.toPrecision(2)}</div>
                        </Tooltip>
                    </div>
                </td>
                <td className="w-20">
                    <SlaProgressBar issue={issue} />
                </td>
                <td>
                    {issue.daysElapsed} {issue.daysElapsed === 1 ? 'day' : 'days'}
                </td>
                {issue.issueData.title === title ? (
                    <td>{title}</td>
                ) : (
                    <td>
                        <Tooltip title={issue.issueData.title}>
                            <div>{title}</div>
                        </Tooltip>
                    </td>
                )}
            </tr>
        );
    }
}
