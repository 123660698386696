import { ChevronDownIcon, QuestionMarkCircleIcon } from '@heroicons/react/solid';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React from 'react';

export default function InfoCard({ children, heading }) {
    return (
        <div className="infoCard inline-block rounded-md mx-auto p-1 text-blue-500 text-m w-96">
            <Accordion>
                <AccordionSummary expandIcon={<ChevronDownIcon className="h-6" />}>
                    <QuestionMarkCircleIcon className="w-5 text-blue-500 mr-1 p-0" /> {heading}
                </AccordionSummary>
                <AccordionDetails className="text-sm">{children}</AccordionDetails>
            </Accordion>
        </div>
    );
}
