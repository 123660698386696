import React, { Component } from 'react';
import HistoryChart from '../charts/history';
import InfoCard from '../utils/infoCard';
import LoadingSpinner from '../utils/loading';
import LoadingError from '../utils/loadingError';
import NoIssues from '../utils/noIssues';
import RedxIssue from './redxIssue';
import Table from '../utils/table';

export default class Redx extends Component {
    render() {
        var issues = this.props.issues;
        if (issues) issues.sort((a, b) => b.normalizedScore - a.normalizedScore);

        if (this.props.isLoadingError) {
            return <LoadingError />;
        } else if (this.props.isLoading) {
            return <LoadingSpinner />;
        } else {
            if (issues.length === 0) {
                return <NoIssues />;
            } else {
                return (
                    <div className="mb-4 flex flex-col">
                        <div className="text-center mt-4">
                            <InfoCard heading="What is RedX?">
                                <div>
                                    Red-X is a Cimpress Cloud Security in-house tool that scans for
                                    DNS misconfiguations that could lead to security
                                    vulnerabilities. More details on Red-X can be found{' '}
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={
                                            'https://cimpress-support.atlassian.net/wiki/spaces/CloudKB/pages/15063351387/Red-X+DNS+Scanning'
                                        }
                                    >
                                        on this page.
                                    </a>
                                </div>
                            </InfoCard>
                        </div>
                        <HistoryChart history={this.props.history} source="RedX" />
                        <Table
                            data={issues}
                            columns={[
                                { label: 'Trello', accessor: '_id', sortable: false },
                                { label: 'Group', accessor: 'group', sortable: true },
                                { label: 'Squad', accessor: 'squad', sortable: true },
                                { label: 'Account', accessor: 'accountName', sortable: true },
                                { label: 'Severity', accessor: 'severity', sortable: true },
                                { label: 'SLA', accessor: 'percentageSLA', sortable: true },
                                { label: 'Days Elapsed', accessor: 'daysElapsed', sortable: true },
                                { label: 'Check', accessor: 'checkName', sortable: true },
                                { label: 'Resource', accessor: 'resource', sortable: true },
                                { label: 'Zone', accessor: 'zoneName', sortable: true },
                            ]}
                            RowClass={RedxIssue}
                        />
                    </div>
                );
            }
        }
    }
}
