import TableBody from './tableBody';
import TableHead from './tableHead';
import { useSortableTable } from './useSortableTable';

const Table = ({ data, columns, RowClass }) => {
    const [tableData, handleSorting] = useSortableTable(data, columns);

    return (
        <>
            <table className="mx-auto my-5 table-auto shadow-lg text-left grow">
                <TableHead {...{ columns, handleSorting }} />
                <TableBody tableData={tableData} RowClass={RowClass} />
            </table>
        </>
    );
};

export default Table;
