import { SortAscendingIcon, SortDescendingIcon } from '@heroicons/react/solid';
import { HiOutlineSelector } from '@react-icons/all-files/hi/HiOutlineSelector';
import { useState } from 'react';
const TableHead = ({ columns, handleSorting }) => {
    const [sortField, setSortField] = useState('');
    const [order, setOrder] = useState('asc');

    const handleSortingChange = (accessor) => {
        const sortOrder = accessor === sortField && order === 'asc' ? 'desc' : 'asc';
        setSortField(accessor);
        setOrder(sortOrder);
        handleSorting(accessor, sortOrder);
    };

    return (
        <thead>
            <tr>
                {columns.map(({ label, accessor, sortable }) => {
                    const sortIcon = sortable ? (
                        sortField === accessor && order === 'asc' ? (
                            <SortAscendingIcon className="inline h-4 w-4" />
                        ) : sortField === accessor && order === 'desc' ? (
                            <SortDescendingIcon className="inline h-4 w-4" />
                        ) : (
                            <HiOutlineSelector className="inline h-4 w-4" />
                        )
                    ) : (
                        ''
                    );
                    var clickableStyle = sortable ? 'cursor-pointer hover:bg-gray-700' : '';
                    return (
                        <th
                            key={accessor}
                            onClick={sortable ? () => handleSortingChange(accessor) : null}
                            className={
                                'bg-slate-600 text-blue-50 sticky top-0 z-10 px-2 text-center ' +
                                clickableStyle
                            }
                        >
                            {label}
                            {sortIcon}
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
};

export default TableHead;
