import React, { Component } from 'react';
import bg from '../images/spaceSkating.jpeg';
import { UserContext } from '../user-context';

export default class Login extends Component {
    static contextType = UserContext;
    constructor(props) {
        super(props);
        this.logout = this.logout.bind(this);
    }

    componentDidMount() {
        this.setState({ userProfile: this.context.userProfile });
    }

    logout() {
        // when you want to guarantee the user is logged out:
        this.context.auth.logout('/');
    }

    render() {
        let loggedIn = false;
        if (this.context.auth) loggedIn = this.context.auth.isLoggedIn();
        let userProfile = null;
        if (this.state) userProfile = this.state.userProfile;
        return (
            <div
                className="contrast-200 h-screen flex flex-col text-white text-xl text-center bg-center bg-auto bg-no-repeat bg-black"
                style={{ backgroundImage: `url(${bg})` }}
            >
                {loggedIn || (
                    <div className="flex-1 pt-10 uppercase font-bold">
                        login before jumping into parallel universe
                    </div>
                )}
                {loggedIn && userProfile && (
                    <div className="m-auto max-w-sm rounded overflow-hidden shadow-lg bg-slate-300 opacity-75 text-black">
                        <img
                            className="w-32 mx-auto rounded-full"
                            src={userProfile.picture}
                            alt="user gravitar"
                        />
                        <div className="px-6 py-4">
                            <h2 className="test-xl font-bold mb-2 capitalize">
                                {userProfile.given_name} {userProfile.family_name}
                            </h2>
                            <p className="text-gray-700 text-lg font-mono">{userProfile.email}</p>
                        </div>
                        <button
                            onClick={this.logout}
                            className="hover:underline hover:cursor-pointer decoration-2 underline-offset-2 hover:decoration-pink-400 text-sky-700 mb-6 border-purple-300 border-2 px-2"
                        >
                            Logout
                        </button>
                    </div>
                )}
                <div className="mt-auto font-extralight p-5 pb-16">
                    ~ by{' '}
                    <a href="https://99designs.com.au/profiles/femelo?category=t-shirt-design&order=featured">
                        Fe Melo
                    </a>
                </div>
            </div>
        );
    }
}
