import React, { Component } from 'react';
import OverallHistoryChart from '../charts/overallHistory';
import SourceBreakdownChart from '../charts/sourceBreakdown';
import GenericIssue from './genericIssue';
import IssueCount from '../charts/issueCount';
import LoadingSpinner from '../utils/loading';
import LoadingError from '../utils/loadingError';
import SlaHistoryChart from '../charts/slaHistory';
import Table from '../utils/table';

export default class Dashboard extends Component {
    render() {
        let allIssues = [];
        for (let issue of this.props.data.snyk) {
            allIssues.push({
                _id: issue._id,
                source: 'Snyk',
                name: `${issue.issueData.title} in ${issue.pkgName} in ${issue.projectName}`,
                daysElapsed: issue.daysElapsed,
                description: '',
                normalizedScore: issue.normalizedScore,
                normalizedSeverity: issue.normalizedSeverity,
                percentageSLA: issue.percentageSLA,
            });
        }
        for (let issue of this.props.data.snykCode) {
            allIssues.push({
                _id: issue._id,
                source: 'Code',
                name: `${issue.title} in ${issue.projectName}`,
                daysElapsed: issue.daysElapsed,
                description: '',
                normalizedScore: issue.normalizedScore,
                normalizedSeverity: issue.normalizedSeverity,
                percentageSLA: issue.percentageSLA,
            });
        }
        for (let issue of this.props.data.redx) {
            allIssues.push({
                _id: issue._id,
                source: 'RedX',
                name: `${issue.checkName} for ${issue.resource}`,
                daysElapsed: issue.daysElapsed,
                description: '',
                normalizedScore: issue.normalizedScore,
                normalizedSeverity: issue.normalizedSeverity,
                percentageSLA: issue.percentageSLA,
            });
        }
        for (let issue of this.props.data.orca) {
            allIssues.push({
                _id: issue._id,
                source: 'Orca',
                name: `${issue.type_string} in ${issue.asset_category} ${issue.asset_name}`,
                daysElapsed: issue.daysElapsed,
                description: '',
                normalizedScore: issue.normalizedScore,
                normalizedSeverity: issue.normalizedSeverity,
                percentageSLA: issue.percentageSLA,
            });
        }
        allIssues.sort((a, b) => b.normalizedScore - a.normalizedScore);

        if (this.props.data.isLoadingError) {
            return <LoadingError />;
        } else if (this.props.data.isLoading) {
            return <LoadingSpinner />;
        } else {
            return (
                <div className="mb-4 flex flex-col">
                    <IssueCount issues={allIssues} />

                    <div className="flex flex-wrap justify-center m-4">
                        <div className="flex justify-center text-center m-1">
                            <div className="bg-gray-100 rounded-lg">
                                <div className="text-gray-800 font-semibold">
                                    Source Breakdown Today
                                </div>
                                <SourceBreakdownChart allIssues={allIssues} />
                            </div>
                        </div>

                        <OverallHistoryChart
                            history={this.props.data.history}
                            sources={['Snyk', 'snykCode', 'Orca', 'RedX']}
                        />
                        <SlaHistoryChart slaViolation={this.props.data.slaViolation} />
                    </div>

                    <h2 className="text-center text-xl text-sky-800 p-2 font-semibold">
                        Issue Details
                    </h2>
                    <Table
                        data={allIssues}
                        columns={[
                            { label: 'Severity', accessor: 'normalizedScore', sortable: true },
                            { label: 'Source', accessor: 'source', sortable: true },
                            { label: 'SLA', accessor: 'percentageSLA', sortable: true },
                            { label: 'Days Elapsed', accessor: 'daysElapsed', sortable: true },
                            { label: 'Issue', accessor: 'name', sortable: true },
                        ]}
                        RowClass={GenericIssue}
                    />
                </div>
            );
        }
    }
}
