import React from 'react';
import bg from '../images/retroMom.jpeg';

export default function NoIssues() {
    return (
        <div className="flex flex-col h-screen text-center bg-white font-comic-neue">
            <div className="mt-5 text-2xl">No issues here! Nicely Done!</div>
            <div className="mx-auto">
                <img className="contrast-200" src={bg} />
            </div>
            <div className="font-extralight bg-white">
                ~ by{' '}
                <a
                    className="hover:bg-teal-200"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://99designs.com/profiles/evgenytodorov?category=illustrations&order=popular"
                >
                    E-T
                </a>
            </div>
        </div>
    );
}
