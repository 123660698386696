import React, { Component } from 'react';
import { Bar, BarChart, CartesianGrid, Label, Legend, Tooltip, XAxis, YAxis } from 'recharts';

export default class SourceBreakdownChart extends Component {
    render() {
        let allIssues = this.props.allIssues;
        let serverities = ['critical', 'high', 'medium', 'low'];
        let serverityBreakdown = [];
        for (let severity of serverities) {
            serverityBreakdown.push({ severity: severity });
        }

        let sources;
        for (let severity of serverities) {
            sources = Array.from(
                allIssues.reduce((agg, issue) => agg.add(issue.source), new Set()),
            );
            for (let source of sources) {
                let count = allIssues.reduce(
                    (count, issue) =>
                        issue.normalizedSeverity === severity && issue.source === source
                            ? count + 1
                            : count,
                    0,
                );
                let bd = serverityBreakdown.filter((bd) => bd.severity === severity)[0];
                bd[source] = count;
            }
        }
        let barColors = [
            '#e18a1e',
            '#8884d8',
            '#82ca9d',
            '#ffc658',
            'purple',
            'lightblue',
            'orange',
        ];
        return (
            <BarChart data={serverityBreakdown} width={600} height={250}>
                <CartesianGrid />
                <XAxis dataKey="severity" />
                <YAxis>
                    <Label value="Issues" angle="-90" position="insideLeft" />
                </YAxis>
                <Tooltip />
                <Legend />
                {sources.map((source, index) => (
                    <Bar dataKey={source} key={index} stackId="a" fill={barColors[index]} />
                ))}
            </BarChart>
        );
    }
}
