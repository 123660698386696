import { PlusCircleIcon } from '@heroicons/react/solid';
import { Tooltip } from '@mui/material';
import React, { Component } from 'react';
import SlaProgressBar from '../utils/slaProgressBar';

const maxMessageLength = 60;

export default class OrcaIssue extends Component {
    constructor(props) {
        super(props);
        this.state = { issue: this.props.issue };
    }

    componentDidMount() {
        if (window.location.hash !== undefined && window.location.hash !== '') {
            let id = this.props.issue._id;
            if (window.location.hash === `#${id}`) {
                this.refs[id].focus();
            }
        }
    }

    render() {
        let { issue, index } = this.props;

        let severity = issue.normalizedSeverity;
        var severityBgColor = 'bg-gray-600';
        if (severity === 'high') {
            severityBgColor = 'bg-red-700';
        } else if (severity === 'medium') {
            severityBgColor = 'bg-amber-600';
        } else if (severity === 'low') {
            severityBgColor = 'bg-yellow-600';
        }

        let account = issue.account_name.split(' ')[0];
        if (account.startsWith('aws-99designs-')) account = account.split('aws-99designs-')[1];
        if (account.startsWith('aws-')) account = account.split('aws-')[1];
        if (account === 'aws-99designs') account = 'bastion';

        let showDetails = issue.details;
        if (showDetails.length > maxMessageLength)
            showDetails = showDetails.substring(0, maxMessageLength - 3) + '...';

        issue.resource = '';
        if ('asset_vendor_id' in issue) {
            issue.resource = issue.asset_vendor_id;
        } else if (
            'model' in issue &&
            'data' in issue.model &&
            'Inventory' in issue.model.data &&
            'UiUniqueField' in issue.model.data.Inventory
        ) {
            issue.resource = issue.model.data.Inventory.UiUniqueField;
        } else {
            issue.resource = issue.asset_unique_id;
        }

        let link = 'https://app.orcasecurity.io/alerts/' + issue.state.alert_id;

        let trelloDescription = encodeURIComponent(
            `**Issue Details**\n
* Details: ${issue.details}
* Type: ${issue.type_string}
* Source: ${issue.source}
* Description: ${issue.description}
* Severity: ${issue.normalizedSeverity}
* Resource: \`${issue.asset_unique_id}\`
* Vendor id: \`${issue.asset_vendor_id}\`
* Account: ${issue.account_name}
* Hostname: ${issue.model?.data.Compute?.Hostname}
* AZ: ${issue.asset_availability_zones ? issue.asset_availability_zones : issue.region}
* Asset type: ${issue.asset_type}
* Asset name: ${issue.asset_name}
* Container image name: ${issue.container_image_name}
* Asset state: ${issue.asset_state}
* Orca link: ${link}
`,
        );
        let trelloLink =
            'https://trello.com/en-GB/add-card?' +
            'name=' +
            encodeURIComponent(`Orca Alert: ${issue.type_string}: ${issue.resource} `) +
            '&' +
            'desc=' +
            trelloDescription;

        return (
            <tr
                className={
                    'hover:underline hover:cursor-pointer decoration-2 underline-offset-2 hover:decoration-pink-400 text-sky-700 odd:bg-gray-200 even:bg-slate-100 focus:outline outline-2'
                }
                tabIndex={index}
                autoFocus
                onClick={() => window.open(link, '_blank', 'noopener,noreferrer')}
                ref={issue._id}
            >
                <td
                    className="hover:text-green-500 hover:cursor-pointer visited:text-black"
                    onClick={(event) => {
                        event.stopPropagation();
                        window.open(trelloLink, '_blank', 'noopener,noreferrer');
                    }}
                >
                    <Tooltip title="Create Trello Card">
                        <PlusCircleIcon className="h-6" />
                    </Tooltip>
                </td>
                <td className="capitalize">{issue.group}</td>
                <td className="capitalize">{issue.squad}</td>
                <td className="capitalize">{account}</td>
                <td>{issue.type_string}</td>
                <td className="text-center">
                    <div
                        className={
                            'inline-block mx-1 px-2 uppercase text-gray-50 content-between text-center rounded-lg text-sm font-bold ' +
                            severityBgColor
                        }
                    >
                        {severity}
                    </div>
                </td>
                <td className="w-20">
                    <SlaProgressBar issue={issue} />
                </td>
                <td>
                    {issue.daysElapsed} {issue.daysElapsed === 1 ? 'day' : 'days'}
                </td>
                {issue.details === showDetails ? (
                    <td>{showDetails}</td>
                ) : (
                    <td>
                        <Tooltip title={issue.details}>
                            <div>{showDetails}</div>
                        </Tooltip>
                    </td>
                )}
                <td>{issue.source}</td>
                <td className="font-mono text-sm">{issue.asset_unique_id}</td>
            </tr>
        );
    }
}
