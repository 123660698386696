import React, { Component } from 'react';
import { CartesianGrid, Label, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';

export default class HistoryChart extends Component {
    render() {
        let history = this.props.history;
        let source = this.props.source;
        let sourceLowerCase = source.toLowerCase();

        let sourceHistory = history.filter((h) => h.source === sourceLowerCase);
        let forGraph = sourceHistory
            .reduce((result, h) => {
                let date = h.date;
                let severity = h.severity;
                let count = h.count;

                let dateResult = result.find((r) => r.date === date);
                if (dateResult !== undefined) {
                    dateResult[severity] = count;
                } else {
                    let dateResult = { date: date };
                    dateResult[severity] = count;
                    result.push(dateResult);
                }

                return result;
            }, [])
            .reverse();

        // set missing values to 0 for graphing
        for (let data of forGraph) {
            for (let severity of ['critical', 'high', 'medium', 'low']) {
                if (!(severity in data)) {
                    data[severity] = 0;
                }
            }
        }

        let visuals = [
            { label: 'critical', color: '#DC2626', strokeWidth: 2 },
            { label: 'high', color: '#B45309', strokeWidth: 2 },
            { label: 'medium', color: '#CA8A04', strokeWidth: 1 },
            { label: 'low', color: '#4B5563', strokeWidth: 1 },
        ];

        return (
            <div className="flex justify-center text-center m-1">
                <div className="bg-gray-100 rounded-lg">
                    <LineChart width={1200} height={250} data={forGraph}>
                        <XAxis dataKey="date" />
                        <YAxis>
                            <Label value="Issues" angle="-90" position="insideLeft" />
                        </YAxis>
                        <Tooltip />
                        <Legend />
                        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
                        {visuals.map((visual) => (
                            <Line
                                type="monotone"
                                dot={false}
                                strokeWidth={visual.strokeWidth}
                                key={visual.label}
                                dataKey={visual.label}
                                stroke={visual.color}
                                animationDuration={500}
                            />
                        ))}
                    </LineChart>
                </div>
            </div>
        );
    }
}
